@import "../variables.scss";

.notification-error {
  background-color: $danger-color;
}

.notification-success {
  background: $primary-gradient;
}

.notification-warning {
  color: black;
  background: $warning-gradient;
}