/* ============= Landing Page 2023 =============== */

:root {
  --main-yellow: #ede53c;
  --secondary-yellow: #fefaa4;
  --main-blue: #0e7988;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


// Navbar
.landing-page-navbar {
  a {
    color: var(--main-blue) !important;
    text-decoration: none;
  }

  .desktop {
    display: None;
    position: fixed;
    width: 80%;
    min-height: 30px;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    box-shadow: 10px 10px 10px #555;
    margin: 2.5% 5% 0 5%;
    padding: 1% 5%;
    z-index: 999;
    border-radius: 15px;
  }

  @media (orientation: landscape) {
    .desktop {
      display: flex;
    }
  }


  #medpal_icon {
    display: block;
    background-image: url("../../assets/images/home.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    width: 150px;
    cursor: pointer;
  }

  .nav {
    color: var(--main-blue);
    display: flex;
    grid-gap: 60px;
    font-family: roboto;
    font-size: 1.25rem;
    font-weight: 400;
  }

  .mobile {
    display: grid;
    grid-template-columns: auto auto;
    position: fixed;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    justify-content: space-between;
    align-items: center;
    z-index: 9999;

    .navbar-links {
      width: 100%;

      a {
        width: 100%;
        text-align: center;
        padding-inline: 0 !important;
      }
    }
  }

  @media (orientation: landscape) {
    .mobile {
      display: none;
    }
  }

  .navbar-brand {
    display: block;
    background-image: url("../../assets/images/home.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 5vh;
    width: 35vw;
    margin: 20px;
  }

  .navbar-links {
    display: none;
    grid-column: 1 / 3;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 10px 10px #555;
    font-weight: 400;
  }

  .navbar-links a {
    display: block;
    color: var(--main-blue);
    font-family: roboto;
    font-size: 1.25rem;
    text-decoration: none;
    padding: 8px;
  }

  .navbar-icon {
    color: var(--main-blue);
    font-size: 24px;
    cursor: pointer;
    margin-right: 20px;
  }

  .navbar-links.show {
    display: flex;
    flex-direction: column;
  }
}


.landing-page {
  html {
    scroll-behavior: smooth;
  }

  .even {
    margin-bottom: 50px;
    text-align: center;
  }

  .even h1 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.25rem;
    font-weight: 700;
  }

  @media (orientation: landscape) {
    .even h1 {
      font-size: 3.5rem;
    }
  }

  .even h2 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.25vh;
    font-weight: 400;
  }

  @media (orientation: landscape) {
    .even h2 {
      line-height: 1.75;
    }
  }

  .even h3 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.5vh;
    font-weight: 200;
  }

  .even h4 {
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 600;
    color: #555;
  }

  .odd h1 {
    font-family: roboto;
    color: white;
    font-size: 2.75rem;
  }

  @media (orientation: landscape) {
    .odd h1 {
      font-size: 3.5rem;
    }
  }

  .odd h2 {
    font-family: roboto;
    color: white;
    font-size: 3vh;
    font-weight: 300;
  }

  .odd h3 {
    font-family: roboto;
    color: white;
    font-size: 2rem;
    font-weight: 200;
  }

  .odd h4 {
    font-family: roboto;
    color: white;
    font-size: 2.25vh;
    font-weight: 400;
  }

  body {
    margin: 0;
    padding: 0;
  }

  ul {
    max-width: 75vw;
    margin: auto;
    text-align: left;
  }

  @media (orientation: landscape) {
    ul {
      margin: 0;
    }
  }

  /**************************************/

  .regular_container {
    max-width: 1300px;
  }

  @media (orientation: portrait) {
    .regular_container {
      height: auto !important;
    }
  }

  .img {
    min-height: 50vh;
  }



  /**************************************/

  .section1 {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/hero.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    justify-items: center;
  }

  .hero {
    width: 85%;
    max-width: 1080px;
    height: 100vh;
    display: grid;
    align-content: center;
  }

  .hero h1 {
    font-size: 5vh;
    color: white;
    text-align: center;
    margin: 0;
    z-index: 1;
  }

  @media (orientation: landscape) {
    .hero h1 {
      text-align: left;
    }
  }

  .hero h2 {
    color: white;
  }

  .hero .stores {
    margin: 0;
    grid-gap: 25px;
  }

  @media (orientation: landscape) {
    .hero .stores {
      grid-gap: 0;
    }
  }

  .medpal_logo_h {
    display: none;
    width: 75%;
    max-width: 400px;
    height: 200px;
    margin-top: 20%;
    background-image: url(../../assets/images/logo_h.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {
    .medpal_logo_h {
      display: block;
    }
  }

  .medpal_logo_v {
    width: 50%;
    max-width: 200px;
    height: 175px;
    margin: auto;
    margin-bottom: 25px;
    background-image: url(../../assets/images/logo_v.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {
    .medpal_logo_v {
      display: none;
      margin-bottom: 50px;
    }
  }

  .hero ul {
    list-style-image: url("../../assets/images/check.svg");
  }

  .hero li {
    font-family: roboto;
    color: white;
    font-size: 1.5rem;
    text-align: left;
    line-height: 2;
  }

  @media (orientation: landscape) {
    .hero li {
      font-size: 2rem;
    }
  }

  .registrarse {
    background: linear-gradient(to bottom, var(--secondary-yellow) 0%, var(--main-yellow) 100%);
    border: none;
    width: 50%;
    min-width: 200px;
    max-width: 400px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
    color: var(--main-blue);
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 900;
    z-index: 1;
    cursor: pointer;
  }

  .hero_devices {
    display: block;
    position: absolute;
    background-image: url("../../assets/images/hero_devices.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 50%;
    margin-top: 10%;
    height: 75vh;
    width: 50vw;
  }

  @media (orientation: portrait) {
    .hero_devices {
      display: none;
    }
  }

  @media (min-aspect-ratio: 2.33) {
    .hero_devices {
      background-size: contain;
    }
  }

  /**************************************/

  .section_intro {
    width: 50%;
    min-width: 300px;
    max-width: 1080px;
    margin: auto;
    padding: 5vh 0;
    text-align: center;
  }

  .section_intro h1 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2rem;
  }

  .section_intro h2 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 1.75rem;
    font-weight: 400;
  }

  /**************************************/

  .section2 {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
  }

  .features1 {
    width: 85%;
    height: 75vh;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  #img_feature1 {
    width: 100%;
    height: auto;
    background-image: url(../../assets/images/caracteristicas_4.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .features1 {
      grid-template-columns: 60% 40%;
      text-align: left;
    }

    .features1 h1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .features1 h2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    #img_feature1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  /**************************************/

  .section3 {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
  }

  .carrusel {
    width: 85%;
    max-width: 1080px;
    margin: auto;
    display: block;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 25% 50% 25%;
  }

  .carrusel h3 {
    color: #555;
    font-size: 1.5rem;
  }

  @media (orientation: landscape) {

    .carrusel {
      display: grid;
    }

    .carrusel h1 {
      font-size: 4rem;
    }
  }

  .features_app_elements {
    display: none;
  }

  .features_web_elements {
    display: none;
  }

  .features_carrusel {
    min-height: 50vh;
    max-width: 512px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text_features_web {
    display: none;
    min-height: 50vh;
    margin: auto;
    flex-direction: column;
    justify-content: center;
  }

  .text_features_app {
    display: none;
    min-height: 50vh;
    margin: auto;
    flex-direction: column;
    justify-content: center;
  }

  .img_carrusel_app {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    margin-bottom: 25px;
    background-image: url("../../assets/images/carrusel1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .img_carrusel_web {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    background-image: url("../../assets/images/carrusel2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .carrusel_buttons {
    margin: 50px;
    display: flex;
    grid-row: 2 / 3;
  }

  @media (orientation: landscape) {
    .carrusel_buttons {
      grid-row: 3 / 4;
    }
  }

  #carrbtn1 {
    width: 25px;
    height: 25px;
    margin: 0 20px;
    border-radius: 50%;
    background-color: var(--main-yellow);
    box-shadow: 5px 5px 5px #555;
    cursor: pointer;
    transition: 0.5s;
  }

  #carrbtn1:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 8px #555;
  }

  #carrbtn2 {
    width: 25px;
    height: 25px;
    margin: 0 20px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 5px 5px 5px #555;
    cursor: pointer;
    transition: 0.5s;
  }

  #carrbtn2:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 8px #555;
  }

  /**************************************/

  .section4 {
    min-height: 500px;
    margin-bottom: 0px;
  }

  @media (orientation: landscape) {
    .section4 .even {
      margin-bottom: 50px;
    }
  }

  .features3 {
    width: 50%;
    min-width: 300px;
    margin: auto;
    padding: 0;
    text-align: center;
  }

  @media (orientation: landscape) {
    .features3 {
      padding: 3vh 0;
    }
  }

  /**************************************/

  .section5 {
    min-height: 500px;
  }

  .features4 {
    background-image: url("../../assets/images/footer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    text-align: center;
    padding-bottom: 23vh;
    padding-top: 5vh;
  }

  .gestor_features {
    display: grid;
    justify-content: center;
    grid-gap: 35px;
  }

  @media (orientation: landscape) {
    .gestor_features {
      display: flex;
    }
  }

  .gestor_features li {
    font-family: roboto;
    color: white;
    font-size: 1.25rem;
    text-align: left;
    line-height: 2;
  }

  #img_gestor1 {
    background-image: url("../../assets/images/gestor_features1.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 8vh;
  }

  #img_gestor2 {
    background-image: url("../../assets/images/gestor_features2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 8vh;
  }

  #img_gestor3 {
    background-image: url("../../assets/images/gestor_features3.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 8vh;
  }

  #img_feature4_container {
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 50vh;
    max-width: 100vw;
    margin: auto;
  }

  @media (orientation: landscape) {
    #img_feature4_container {
      max-width: 50vw;
    }
  }

  #img_feature4 {
    position: relative;
    height: 100%;
    width: 100%;
    top: -20vh;
  }

  @media (orientation: portrait) {
    #img_feature4 {
      top: -12vh
    }
  }

  /**************************************/

  .stores {
    width: 50%;
    max-width: 768px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 25px;
  }

  @media (orientation: portrait) {
    .stores {
      grid-template-columns: auto;
    }
  }

  .playstore {
    background-image: url("../../assets/images/playstore.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 75px;
    cursor: pointer;
    transition: transform 300ms ease-out;
  }

  .playstore:hover {
    transform: scale(1.1);
  }

  .appstore {
    background-image: url("../../assets/images/appstore.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 75px;
    cursor: pointer;
    transition: transform 500ms ease-out;
  }

  .appstore:hover {
    transform: scale(1.1);
  }

  /**************************************/

  .register-form {
    position: relative;
    width: 100%;
    display: grid;
    background-color: #e9f7fa;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
    margin-bottom: 0 !important;
  }

  .form {
    form {
      border-radius: 10px;
      padding: 20px;
      display: inline-block;
    }
  }

  .form h1 {
    font-size: 2rem;
  }

  .form h3 {
    padding-top: 50px;
  }

  .form {

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=phone],
    input[type=list],
    select,
    textarea {
      width: 100%;
      padding: 15px 0;
      min-width: 300px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 2px solid #a5d8e4;
      background-color: #f8f8f8;
      font-size: 1.25rem;
      text-align: center;
      color: #555;
    }

    input[type=checkbox] {
      display: block;
    }

    input[type=submit] {
      background-color: #63a9b8;
      color: white;
      padding: 14px 20px;
      margin-top: 10px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }


    label {
      width: 100%;
      display: inline-block;
      color: #9d9fa0;
      text-align: left;
      margin-bottom: 10px;
      font-family: roboto;
      font-size: 1rem;
      font-weight: 200;
      padding-top: 10%;
    }
  }




  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .check input,
  .check label {
    padding: 0;
    margin: 0;
  }

  .check label {
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 10px;
  }

  @media (orientation: landscape) {
    .form h1 {
      font-size: 4rem;
    }

    .form {
      label {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }


    .check label {
      font-size: 1rem;
    }
  }

  /**************************************/

  .tyc {
    display: grid;
    height: 5vh;
    justify-content: center;
    align-items: center;
    font-family: roboto;
    cursor: pointer;
  }

  /**************************************/
  /***************Nosotros***************/
  /**************************************/

  .space_nav {
    height: 15vh;
    min-height: 100px;
  }

  .space_nav2 {
    height: 5vh;
    min-height: 50px;
  }

  /**************************************/

  .abus_section1 {
    width: 100%;
    min-height: 90vh;
    display: grid;
    justify-items: center;
  }

  .about_us {
    max-width: 75%;
    min-height: 60vh;
    display: grid;
    align-content: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  @media (orientation: landscape) {

    .about_us {
      max-width: 1080px;
      grid-template-columns: 50% 80%;
      text-align: left;
    }
  }

  .about_us h1 {
    font-size: 2.25rem;
    font-family: roboto;
    color: var(--main-blue);
  }

  .about_us h3 {
    font-size: 1.5rem;
    font-family: roboto;
    font-weight: 200;
    color: #555;
  }

  .features6_container {
    margin: auto;
    max-width: 512px;
    margin-top: 25px;
  }

  @media (orientation: landscape) {
    .features6_container {
      margin: 0;
    }
  }

  .features6 {
    display: flex;
    justify-content: left;
    align-items: center;
    grid-gap: 20px;
  }

  .text_features6 {
    font-family: roboto;
    font-size: 1.25rem;
    color: #555;
    font-weight: 600;
  }

  #icon_features6_1 {
    background-image: url("../../assets/images/abus_features6_1.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 65px;
    height: 100px;
  }

  #icon_features6_2 {
    background-image: url("../../assets/images/abus_features6_2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 65px;
    height: 100px;
  }

  #icon_features6_3 {
    background-image: url("../../assets/images/abus_features6_3.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 65px;
    height: 100px;
  }

  #img_about_us {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-image: url('../../assets/images/about_us.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .about_us h1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .about_us h3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    #img_about_us {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  /**************************************/
  /***************Contacto***************/
  /**************************************/

  .contact_section {
    max-width: 85%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (orientation: landscape) {
    .contact_section {
      justify-content: left;
    }
  }

  .contact {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .contact h1 {
    margin-top: 0;
  }

  .contact h4 {
    color: #555;
    font-size: 1rem;
  }

  .contact form {
    margin: auto;
    background-color: #e9f7fa;
    padding: 30px;
  }

  @media (orientation: landscape) {
    .contact form {
      padding: 50px;
    }
  }

  .contact .registrarse {
    margin: 0;
  }

  .contact label {
    padding: 0;
    margin: 10px;
  }

  .contact input {
    padding: 10px 0;
  }

  .contact textarea {
    padding: 10px 10px;
    resize: none;
    text-align: justify;
  }

  .info {
    display: block;
    margin-top: 25px;
  }

  @media (orientation: landscape) {
    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0 20px;
    }
  }

  #phone {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #icon_phone {
    background-image: url("../../assets/images/phone.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
  }

  #mail {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #icon_mail {
    background-image: url("../../assets/images/mail.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
  }
}