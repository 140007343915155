.terms-content {
  table {
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      border: 1px solid black;
      text-align: left;
      padding: 8px;
    }

  }
}