@import "variables.scss";
$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

#{$a-tags} {
    color: inherit;
    text-decoration: none;
}

 #{$a-tags-hover} {
     color: var(--primarycolor);
 }


/* ============= Styles =============== */
/*--------------------------------------------------------------------------
Constants Colors and Fonts
--------------------------------------------------------------------------*/

:root {
    --greymedium: #969696;
    --greydark: #646464;
    --tertearycolor: #3177b9;
    --secondarycolor: #efdb26;
    --primarycolor: #19a8bf;
    --shadow: #bbb;
    --background: #e9f7fa;
}

/*--------------------------------------------------------------------------
General
--------------------------------------------------------------------------*/

body {
    background-color: white !important;
}

.landing-page {
    background-color: white;
}


.contact_page {
    background-image: none;
    background-color: var(--background) !important;
}

@media (orientation: landscape) {
    .contact_page {
        background-image: url('../assets/images/contacto.png');
        background-position: right;
        background-repeat: no-repeat;
    }
}

/*--------------------------------------------------------------------------
Footer
--------------------------------------------------------------------------*/

.footer {
    display: flex;
    background-color: white;
}

.footer.landing {
    display: block;
}