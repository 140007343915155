/** WorkSans **/
@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: url(../assets/fonts/WorkSans/WorkSans-ExtraBold.ttf);
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: url(../assets/fonts/WorkSans/WorkSans-Regular.ttf);
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url(../assets/fonts/WorkSans/WorkSans-Bold.ttf);
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url(../assets/fonts/WorkSans/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: url(../assets/fonts/WorkSans/WorkSans-Medium.ttf);
}

@font-face {
  font-family: 'WorkSans-light';
  src: url(../assets/fonts/WorkSans/WorkSans-Light.ttf);
}