html {
  scroll-behavior: smooth;
}

.doctor-landing-page {


  a {
    color: var(--main-blue) !important;
    text-decoration: none;
  }

  html {
    scroll-behavior: smooth;
  }

  :root {
    --main-yellow: #ede53c;
    --secondary-yellow: #fefaa4;
    --main-blue: #0e7988;
  }

  .even {
    background-color: #e9f7fa;
  }

  .even h1 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 3rem;
  }

  .even h2 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.75vh;
    font-weight: 400;
  }

  .even h3 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.5vh;
    font-weight: 200;
  }

  .even h4 {
    font-family: roboto;
    font-size: 2.25vh;
    font-weight: 600;
    color: #555;
  }

  @media (orientation: landscape) {
    .even h2 {
      line-height: 1.75;
    }
  }

  .odd h1 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 3rem;
  }

  .odd h2 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.75vh;
    font-weight: 300;
  }

  .odd h3 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2.5vh;
    font-weight: 200;
  }

  .odd h4 {
    font-family: roboto;
    font-size: 2.25vh;
    font-weight: 400;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .features {
    width: 85%;
    display: grid;
    justify-content: center;
    align-content: center;
  }

  /**************************************/

  .section1 {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/hero.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    justify-items: center;
  }

  .hero {
    width: 100%;
    max-width: 1920px;
    height: 100vh;
    display: grid;
    grid-gap: 5% 0;
    align-content: center;
    justify-items: center;
  }

  @media (orientation: landscape) {
    .hero {
      width: 85%;
      justify-items: left;
    }
  }

  .hero h1 {
    font-size: 5vh;
    margin: 0;
    color: white;
  }

  .side_l {
    width: 85%;
    text-align: center;
  }

  @media (orientation: landscape) {
    .side_l {
      text-align: left;
      width: 40%;     
    }
  }

  .registrarse {
    background: linear-gradient(to bottom, var(--secondary-yellow) 0%, var(--main-yellow) 100%);
    border-radius: 5px;
    border: none;
    color: var(--main-blue);
    cursor: pointer;
    font-family: roboto;
    font-size: 1.5rem;
    font-weight: 900;
    margin: 30px 0;
    max-width: 400px;
    min-width: 200px;
    padding: 20px;
    width: 50%;
    z-index: 1;
  }

  .medpal_logo_h {
    display: none;
    width: 75%;
    max-width: 400px;
    height: 200px;
    background-image: url(../../assets/images/logo_h.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {
    .medpal_logo_h {
      display: block;
    }
  }

  .medpal_logo_v {
    width: 50%;
    max-width: 200px;
    height: 175px;
    background-image: url(../../assets/images/logo_v.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {
    .medpal_logo_v {
      display: none;
    }
  }

  .hero_devices {
    display: block;
    position: absolute;
    background-image: url("../../assets/images/hero_devices.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 55%;
    margin-top: 8%;
    height: 90vh;
    width: 70vw;
  }

  @media (orientation: portrait) {
    .hero_devices {
      display: none;
    }
  }

  /**************************************/

  .section_intro {
    width: 50%;
    min-width: 300px;
    max-width: 1080px;
    margin: auto;
    padding: 5vh 0;
    text-align: center;
  }

  .section_intro h1 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 2rem;
    margin-top: 0;
  }

  @media (orientation: landscape) {
    .section_intro h1 {
      margin-top: 75px;
    }
  }

  .section_intro h2 {
    font-family: roboto;
    color: var(--main-blue);
    font-size: 1.75rem;
    font-weight: 400;
  }

  /**************************************/

  .section2 {
    width: 100%;
    min-height: 75vh;
    display: grid;
    justify-items: center;
  }

  .features1 {
    width: 55%;
    min-height: 50vh;
    display: grid;
    grid-gap: 0;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
    margin-bottom: 25px;
  }

  #img_feature1 {
    width: 90vw;
    height: 100%;
    min-height: 33vh;
    background-image: url(../../assets/images/vademecum.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {
    .features1 {
      grid-template-columns: 40% 60%;
      text-align: left;
      margin-bottom: 0px;
      grid-gap: 0 50px;
    }

    .features1 h2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .features1 h1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    #img_feature1 {
      width: 100%;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 5;
      margin: 0;
      background-position: center right;
    }
  }

  .features1_1 {
    max-width: 1080px;
    align-content: center;
    justify-items: center;
    text-align: center;
  }

  .highlight {
    max-width: 1080px;
    border-radius: 15px;
    background-color: white;
    padding: 5px;
    margin: auto;
    box-shadow: 7px 7px 12px #555;
  }

  /**************************************/

  .section3 {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    padding-bottom: 5%;
  }

  .features2 {
    max-width: 1080px;
    min-height: 75vh;
    display: grid;
    align-content: center;
    text-align: center;
    grid-template-columns: 100%;
    margin-bottom: 50px;
  }

  #img_feature2 {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-image: url(../../assets/images/caracteristicas_2.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .features2 {
      grid-template-columns: 60% 40%;
      text-align: left;
      margin-bottom: 0px;
    }

    .features2 h1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .features2 h2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    #img_feature2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  .features2_1 {
    max-width: 1080px;
    width: 100%;
    height: auto;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
    background-color: #fefde8;
    border-radius: 30px;
    padding: 0px;
  }

  .features2_1 h1 {
    font-size: 2rem;
  }

  .features2_1 h1,
  .features2_1 h2 {
    color: #555;
    width: 85%;
  }

  #img_feature2_1 {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-image: url(../../assets/images/campanas_medicas.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .features2_1 {
      grid-template-columns: 40% 60%;
      width: 85%;
      text-align: left;
      padding: 50px;
      border-radius: 50px;
    }

    .features2_1 h1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      font-size: 3rem;
    }

    .features2_1 h2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    #img_feature2_1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  /**************************************/

  .section4 {
    width: 100%;
    min-height: 75vh;
    display: grid;
    justify-items: center;
  }

  .features3 {
    max-width: 1080px;
    height: auto;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  #img_feature3 {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-image: url(../../assets/images/historia_clinica.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .features3 {
      grid-template-columns: 50% 50%;
      text-align: left;
      grid-gap: 0 10%;
    }

    .features3 h2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .features3 h1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    #img_feature3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  /**************************************/

  .section5 {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    padding-bottom: 5%;
  }

  .features4 {
    max-width: 1080px;
    min-height: 65vh;
    display: grid;
    align-content: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  #img_feature4 {
    width: 100%;
    height: auto;
    min-height: 33vh;
    background-image: url(../../assets/images/gestor_citas.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (orientation: landscape) {

    .features4 {
      grid-template-columns: 50% 50%;
      grid-gap: 0 50px;
      text-align: left;
    }

    .features4 h1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .features4 h2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    #img_feature4 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 5;
    }
  }

  /**************************************/

  .section6 {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
  }

  .reviews {
    max-width: 1080px;
    width: 85%;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
    margin: 5% 0;
  }

  .reviews h3 {
    max-width: 1080px;
    line-height: 2.25rem;
    color: #555;
    font-weight: 300;
  }

  @media (orientation: landscape) {
    .reviews h1 {
      max-width: 768px;
    }
  }

  #img_customer {
    width: 50vw;
    max-width: 150px;
    height: 50vw;
    max-height: 150px;
    background-image: url(../../assets/images/review.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 100%;
  }

  /**************************************/

  .form {
    width: 100%;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    grid-template-columns: 100%;
  }

  .form h1 {
    font-size: 2rem;
  }

  .form h3 {
    padding-top: 50px;
  }

  form {
    border-radius: 10px;
    padding: 20px;
    display: inline-block;
  }

  label {
    width: 100%;
    display: inline-block;
    color: #9d9fa0;
    text-align: left;
    margin-bottom: 10px;
    font-family: roboto;
    font-size: 1rem;
    font-weight: 200;
    padding-top: 10%;
  }

  input[type=text],
  input[type=email],
  input[type=password] {
    width: 100%;
    padding: 15px 0;
    min-width: 300px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #a5d8e4;
    background-color: #f8f8f8;
    font-size: 1.25rem;
    text-align: center;
    color: #555;
  }

  input[type=checkbox] {
    display: block;
  }

  input[type=submit] {
    background-color: #63a9b8;
    color: white;
    padding: 14px 20px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .check input,
  .check label {
    padding: 0;
    margin: 0;
  }

  .check label {
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 10px;
  }

  @media (orientation: landscape) {
    .form h1 {
      font-size: 4rem;
    }

    label {
      font-size: 1.25rem;
      font-weight: 400;
    }

    .check label {
      font-size: 1rem;
    }
  }
}