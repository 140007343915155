@import '../fonts.scss';

.text-message {
  h2 {
    font-size: 20px !important;
    font-family: WorkSans-SemiBold !important;
  }

  p {
    font-size: 16px;
    font-family: WorkSans-Regular !important;
  }



}